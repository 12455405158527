import { StatusBar } from "expo-status-bar";
import { Platform, StyleSheet } from "react-native";
import { Text, View } from "../components/Themed";
import { MonoText } from "../components/StyledText";
import HelpInfo from "../components/HelpInfo";
import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";

import * as packageJson from "../package.json";
import ObjectiveGuitarLogo from "../components/Logo";

function AppVersion() {
  const theme = useColorScheme();

  return (
    <View>
      {/* <Text style={styles.infoText}>App version: </Text> */}
      <View
        style={styles.codeHighlightContainer}
        lightColor={Colors.light.codeHighlightBackground}
        darkColor={Colors.dark.codeHighlightBackground}
      >
        <MonoText style={[{ color: Colors[theme].tint }]}>
          {`v${packageJson.version}` || "version error"}
        </MonoText>
      </View>
    </View>
  );
}

export default function ModalScreen() {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>💡 Tips 💡</Text>
      <View
        style={styles.separator}
        lightColor={Colors.light.pickerBackground}
        darkColor={Colors.dark.pickerBackground}
      />

      <HelpInfo />

      <View style={styles.footer}>
        <ObjectiveGuitarLogo />
        <AppVersion />
      </View>

      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "5vh",
  },
  title: {
    fontSize: 28,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 15,
    height: 1,
    width: "80%",
  },
  footer: {
    position: "absolute",
    bottom: "2vh",
    width: "80%",
    display: "flex",
    alignItems: "center",
  },
  codeHighlightContainer: {
    width: "150%",
    borderRadius: 3,
    paddingHorizontal: 4,
    marginVertical: 7,
    alignSelf: "center",
    alignItems: "center",
  },
  versionText: {
    lineHeight: 24,
  },
});
