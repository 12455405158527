import { useEffect, useState } from "react";
import { Pressable, StyleSheet } from "react-native";
import { View, Text } from "../Themed";
import SoundPlayer, { withDelay, afterDelay } from "../../models/SoundPlayer";
import Cadence from "../../models/Cadence";
import Note from "../../models/Note";
import Scale from "../../models/Scale";
import NoteInfoButton, {
  ButtonContainer,
  DisplayOption,
} from "./NoteInfoButton";

const player = new SoundPlayer();

/**
 *
 * @param notes - The sample set.
 * @returns A random note.
 */
function pickRandomNote(notes: Note[]): Note {
  return notes[Math.floor(Math.random() * notes.length)];
}

/**
 * Identifies the degree of the note in the scale and the steps towards the closer tonic.
 * @param note - The note to resolve in the scale.
 * @param notes - The list of notes in the scale sorted from tonic to tonic (octave).
 * @returns A list of of notes.
 */
function inferResolution(note: Note, notes: Note[]): Note[] {
  const degree = notes.indexOf(note) + 1;
  if (degree > notes.length / 2) {
    return notes.slice(degree - 1);
  }
  return notes.slice(0, degree).reverse();
}

function PlaySoundButton({
  label: baseText,
  duration,
  onPress,
}: {
  label: string;
  duration: number;
  onPress: () => void;
}) {
  const playingText = baseText + " 🔊";
  const notPlayingText = baseText + " 🔈";

  const [buttonText, setButtonText] = useState(notPlayingText);

  const handlePress = () => {
    setButtonText(playingText);
    onPress();
    setTimeout(() => setButtonText(notPlayingText), duration);
  };

  return (
    <Pressable onPress={handlePress}>
      <Text>{buttonText}</Text>
    </Pressable>
  );
}

export default function SolfegeExercise({
  scale,
  cadence,
  displayOption: buttonOption,
}: {
  scale: Scale;
  cadence: Cadence;
  displayOption: DisplayOption;
}) {
  player.restartAudioContext();

  const notes = Array.from(scale.notes);

  const [query, setQuery] = useState(() => pickRandomNote(notes));

  const resolution = inferResolution(query, notes);

  useEffect(() => {
    /**
     * Though not strictly a side effect, using useEffect hook to prevent
     * unexpectedly calling startExercise during other re-renders.
     */
    startExercise(cadence, query);
  }, [query]);

  /**
   * Play cadence and then the query tone.
   * @param cadence - A chord progression that establishes the key.
   * @param query - The tone the user needs to guess.
   */
  async function startExercise(cadence: Cadence, query: Note): Promise<void> {
    await player.playChords(cadence.chords);
    await afterDelay(() => player.playNote(query), 750);
  }

  /**
   * If the user guesses the query note, play the resolution of that note then resume the exericse with a random note.
   * Otherwise, play the note of the wrong guess.
   * @param guess - The user's guess.
   */
  const handleGuess = async (guess: Note): Promise<void> => {
    if (guess === query) {
      console.debug("correct!");
      await player.playNotes(resolution, 750 / 2);
      await afterDelay(() => setQuery(pickRandomNote(notes)), 1000);
    } else {
      console.debug("wrong!");
      await withDelay(() => player.playNote(guess), 750);
    }
  };

  return (
    <>
      <View style={styles.container}>
        <PlaySoundButton
          label="CADENCE"
          duration={4500}
          onPress={() => startExercise(cadence, query)}
        />
        <PlaySoundButton
          label="NOTE"
          duration={750}
          onPress={() => player.playNote(query)}
        />
      </View>
      <ButtonContainer>
        {notes.map((note, i) => {
          return (
            /* Generate a button for each note in scale */
            <NoteInfoButton
              key={`button-${i}`}
              note={note}
              scaleSize={notes.length}
              displayOption={buttonOption}
              correct={query === note}
              showMistakes={true}
              onPress={() => handleGuess(note)}
            />
          );
        })}
      </ButtonContainer>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "85%",
  },
});
