import Note from "./Note";
import Chord from "./Chord";
import Scale from "./Scale";
import { Major, Minor, Chromatic } from "../constants/Scales";

/**
 * Constructs a I-IV-V-I chord progression of chords with the following voicings:
 *
 * I: [ Octave3-Note1, Octave4-Note1, Octave4-Note3, Octave4-Note5 ]
 * IV: [ Octave3-Note4, Octave4-Note4, Octave4-Note6, Octave5-Note0 ]
 * V: [ Octave3-Note5, Octave4-Note5, Octave4-Note7, Octave5-Note2 ]
 *
 * In Solfege the chords are represented as:
 * Major, Chromatic
 * I: [ Do3, Do4, Mi4, Sol4 ]
 * IV: [ Fa3, Fa4, La4, Do4 ]
 * V: [ Sol3, Sol4, Ti4, Re4 ]
 *
 * Minor
 * I: [ Do3, Do4, Me4, Sol4 ]
 * IV: [ Fa3, Fa4, Le4, Do4 ]
 * V: [ Sol3, Sol4, Te4, Re4 ]
 *
 * Notation: SolfegeOctave
 */
export default class Cadence {
  _I: Chord;
  _IV: Chord;
  _V: Chord;

  constructor(tonic: string, scaleName: string) {
    const octave3Scale = new Scale(tonic, scaleName, 3).notes;
    const octave4Scale = new Scale(tonic, scaleName, 4).notes;
    const octave5Scale = new Scale(tonic, scaleName, 5).notes;

    switch (scaleName) {
      case Major:
      case Minor:
        this._I = new Chord([
          octave3Scale[0],
          octave4Scale[0],
          octave4Scale[2],
          octave4Scale[4],
        ]);
        this._IV = new Chord([
          octave3Scale[3],
          octave4Scale[3],
          octave4Scale[5],
          octave5Scale[0],
        ]);
        this._V = new Chord([
          octave3Scale[4],
          octave4Scale[4],
          octave4Scale[6],
          octave5Scale[1],
        ]);
        break;
      case Chromatic:
        this._I = new Chord([
          octave3Scale[0],
          octave4Scale[0],
          octave4Scale[4],
          octave4Scale[7],
        ]);
        this._IV = new Chord([
          octave3Scale[5],
          octave4Scale[5],
          octave4Scale[9],
          octave5Scale[0],
        ]);
        this._V = new Chord([
          octave3Scale[7],
          octave4Scale[7],
          octave4Scale[11],
          octave5Scale[2],
        ]);
        break;
      default:
        console.error(
          "failed to create Cadence for unknown scale: ",
          scaleName
        );
        throw new Error(
          `failed to create Cadence for unknown scale: ${scaleName}`
        );
    }
  }

  get chords() {
    return [this._I, this._IV, this._V, this._I];
  }
}
