import * as WebBrowser from "expo-web-browser";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, View } from "./Themed";

export default function HelpInfo() {
  return (
    <View>
      <View style={styles.helpContainer}>
        <Text style={styles.infoText}>
          🔇 If you are not hearing any sound playback, try disabling silent
          mode on your device.
        </Text>
        {__DEV__ && (
          <TouchableOpacity onPress={handleHelpPress} style={styles.helpLink}>
            <Text style={[styles.infoText, { color: "#aaa" }]}>
              💻 Tap here if your app doesn't automatically update after making
              changes
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

function handleHelpPress() {
  WebBrowser.openBrowserAsync(
    "https://docs.expo.io/get-started/create-a-new-app/#opening-the-app-on-your-phonetablet"
  );
}

const styles = StyleSheet.create({
  getStartedContainer: {
    alignItems: "center",
    marginHorizontal: 50,
  },
  infoText: {
    lineHeight: 24,
    textAlign: "center",
  },
  helpContainer: {
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: "center",
  },
  helpLink: {
    paddingVertical: 15,
  },
});
