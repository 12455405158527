import * as Tone from "tone";
import Chord from "./Chord";
import Note from "./Note";

const noteVolume = -6; // decibles
const chordVolume = -12; // decibles

export async function withDelay(fn: any, ms: number): Promise<void> {
  return new Promise((resolve) => {
    fn();
    setTimeout(() => resolve(undefined), ms);
  });
}

export async function afterDelay(fn: any, ms: number): Promise<any> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(fn()), ms);
  });
}

export default class SoundPlayer {
  noteSynth: Tone.PolySynth<Tone.Synth<Tone.SynthOptions>>;
  chordSynth: Tone.PolySynth<Tone.Synth<Tone.SynthOptions>>;

  constructor() {
    this.noteSynth = new Tone.PolySynth(Tone.Synth, {
      volume: noteVolume,
    }).toDestination();
    this.chordSynth = new Tone.PolySynth(Tone.Synth, {
      volume: chordVolume,
    }).toDestination();
  }

  restartAudioContext(): void {
    this.noteSynth.releaseAll();
    this.noteSynth.disconnect();
    // this.noteSynth.dispose();

    this.chordSynth.releaseAll();
    this.chordSynth.disconnect();
    // this.chordSynth.dispose();

    this.noteSynth = new Tone.PolySynth(Tone.Synth, {
      volume: noteVolume,
    }).toDestination();
    this.chordSynth = new Tone.PolySynth(Tone.Synth, {
      volume: noteVolume,
    }).toDestination();
  }

  playNote(note: Note, duration: string = "8n"): void {
    this.noteSynth.triggerAttackRelease(note.value, duration, "+0.05");
  }

  playChord(chord: Chord, duration: string = "8n"): void {
    const notes = chord.toList().map((note) => note.value);

    this.chordSynth.triggerAttackRelease(notes, duration, "+0.05");
  }

  async playNotes(notes: Note[], delay: number = 750): Promise<void> {
    for (let i = 0; i < notes.length; i++) {
      await withDelay(() => this.playNote(notes[i]), delay);
    }
  }

  async playChords(chords: Chord[], delay: number = 750): Promise<void> {
    for (let i = 0; i < chords.length; i++) {
      await withDelay(() => this.playChord(chords[i]), delay);
    }
  }
}
